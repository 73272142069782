<template>
  <b-container
    fluid
  >
    <div class="d-flex flex-column flex-md-row justify-content-md-between mb-0 pb-0">
      <div
        class="order-2 order-md-1"
      >
        <div>
          <b-button
            size="sm"
            variant="success"
            :disabled="state.busy"
            @click="onCreatePaymentRequest"
          >
            Create Request
          </b-button>
        </div>
      </div>
      <div
        class="order-1 order-md-2 align-self-end"
      >
        <b-breadcrumb
          v-if="$route.meta.breadcrumb"
          class="mb-0"
          :items="$route.meta.breadcrumb || []"
        />
      </div>
    </div>
    <!-- <nav
        class="iq-float-menu d-flex justify-content-center align-items-center d-md-none bg-success"
        style="border-radius: 50% 50%; margin: 20px;"
        @click="onCreatePaymentRequest"
      >
        <b-icon
          icon="plus-lg"
        />
      </nav> -->
    <b-tabs
      v-model="state.currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
      class="mt-3"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', state.currentTabIndex === 0)" /> Pending
        </template>
        <PaymentRequestPending
          ref="prPending"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
          @onEdit="onEditPaymentRequest"
          @onView="onViewPaymentRequest"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-text', state.currentTabIndex === 1)" /> Drafts
        </template>
        <PaymentRequestDraft
          ref="prDraft"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
          @onEdit="onEditPaymentRequest"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-archive', state.currentTabIndex === 2)" /> Histories
        </template>
        <PaymentRequestHistories
          ref="prHistories"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
          @onEdit="onEditPaymentRequest"
          @onView="onViewPaymentRequest"
        />
      </b-tab>
    </b-tabs>

    <!-- Modals -->

    <b-modal
      id="modal-payment-request"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      aria-hidden="false"
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i
                :class="['ri-file-text-line h4', {
                  'text-danger fat fa-shake': vTabPaymentRequest()
                }]"
              />
              <b
                :class="['is-tab-active ml-1', {
                  'text-danger': vTabPaymentRequest()
                }]"
              >
                Details
              </b>
            </div>
          </template>

          <ValidationObserver
            ref="formPaymentRequest"
          >
            <b-form
              role="form"
              novalidate
              @submit.prevent
            >
              <b-row>
                <!-- CENTERS -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          v-if="Number(lists.systemOptions.OPTIONS_SELECT_FISCAL_YEAR) === 1"
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="fiscal_year"
                            name="fiscal year"
                            rules="required"
                          >
                            <b-form-group
                              label="Fiscal Year"
                              label-for="pr_fiscal_year"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-form-select
                                id="pr_fiscal_year"
                                v-model="paymentRequest.fiscal_year"
                                label="fiscal_name"
                                :options="lists.fiscalYears"
                                :state="(errors.length > 0 ? false : null)"
                                :disabled="state.busy"
                              >
                                <template #first>
                                  <b-form-select-option
                                    :value="null"
                                    disabled
                                  >
                                    -- select fiscal year here --
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                      </b-row>

                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="budget_center"
                            name="budget center"
                            rules="required"
                          >
                            <b-form-group
                              label="Budget Center"
                              label-for="pr_budget_center"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <v-select
                                v-model="selected.budgetCenter"
                                input-id="pr_budget_center"
                                size="sm"
                                type="text"
                                label="budget_code"
                                placeholder="search budget center here"
                                :options="lists.budgetCenters"
                                :class="(errors.length > 0 ? 'is-invalid' : null)"
                                :disabled="(fetching.budgetCenters || fetching.itemCenters || fetching.approvedByApprovers)"
                                :loading="fetching.budgetCenters"
                              >
                                <template #no-options="">
                                  no available budget center
                                </template>
                              </v-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="item_description"
                            name="item description"
                            rules="required"
                          >
                            <b-form-group
                              label="Item Description"
                              label-for="pr_item_description"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <v-select
                                v-model="selected.itemCenter"
                                input-id="pr_item_description"
                                size="sm"
                                type="text"
                                label="item_name"
                                placeholder="search item description here"
                                :options="lists.itemCenters"
                                :class="(errors.length > 0 ? 'is-invalid' : null)"
                                :disabled="(fetching.itemCenters || fetching.budgetCenters)"
                                :loading="fetching.itemCenters"
                              >
                                <template #option="{ item_name }">
                                  <div class="d-flex flex-column">
                                    <strong>{{ item_name }}</strong>
                                  </div>
                                </template>
                                <template #no-options="">
                                  no available item description
                                </template>
                              </v-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                      </b-row>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="particulars"
                        vid="particulars"
                        rules="required|max:50"
                      >
                        <b-form-group
                          class="mb-2"
                        >
                          <label
                            for="pr_particulars"
                            class="d-flex justify-content-between"
                          >
                            <b>Particulars</b>
                            <span>{{ paymentRequest.particulars ? paymentRequest.particulars.length : 0 }} / 50</span>
                          </label>
                          <b-textarea
                            id="pr_particulars"
                            v-model="paymentRequest.particulars"
                            rows="3"
                            max-rows="6"
                            maxlength="50"
                            placeholder="enter particulars here"
                            :state="(errors.length > 0 ? false : null)"
                            @keydown="onKeydownParticulars"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="text-right py-0" />
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>
                    <!-- BUSINESS PARTNER -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="budget_partner"
                            name="business partner"
                            rules="required"
                          >
                            <b-form-group
                              label="Business Partner"
                              label-for="pr_business_partner"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <v-select
                                v-model="selected.businessPartner"
                                input-id="pr_business_partner"
                                type="text"
                                label="partner_name"
                                placeholder="search business partner here"
                                append-to-body
                                :calculate-position="calculateDropPosition"
                                :options="lists.businessPartners"
                                :class="(errors.length > 0 ? 'is-invalid' : null)"
                                :disabled="fetching.businessPartners"
                                :loading="fetching.businessPartners"
                                :filter="fuseBusinessPartnerFilter"
                                @search="getBusinessPartner"
                              >
                                <template #option="{ tin, partner_name }">
                                  <div class="d-flex flex-column pb-2">
                                    <span class="text-wrap">{{ partner_name }}</span>
                                    <strong>{{ tin }}</strong>
                                  </div>
                                </template>
                                <template #no-options="">
                                  no available business partner
                                </template>
                              </v-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="TIN"
                            label-for="pr_bp_tin"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tin"
                              v-model="businessPartner.tin"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="ATC"
                            label-for="pr_bp_tax_atc"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tax_atc"
                              v-model="taxCode.atc"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Type"
                            label-for="pr_bp_partner_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_partner_type"
                              v-model="businessPartner.partner_type"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="paymentRequest.transaction_type === 'With-2307'"
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="report_period"
                            name="2307 period"
                            rules="required"
                          >
                            <b-form-group
                              label="2307 Period"
                              label-for="pr_report_period"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-form-select
                                id="pr_report_period"
                                v-model="paymentRequest.report_period"
                                :options="lists.reportPeriods"
                                :state="(errors.length > 0 ? false : null)"
                                :disabled="(fetching.businessPartners || fetching.options)"
                              >
                                <template #first>
                                  <b-form-select-option
                                    :value="null"
                                    disabled
                                  >
                                    -- select report period here --
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          v-if="paymentRequest.transaction_type === 'With-2307'"
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="report_year"
                            name="report year"
                            rules="required"
                          >
                            <b-form-group
                              label="2307 Year"
                              label-for="pr_report_year"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-form-select
                                id="pr_report_year"
                                v-model="paymentRequest.report_year"
                                :options="lists.reportYears"
                                :state="(errors.length > 0 ? false : null)"
                                :disabled="(fetching.businessPartners || fetching.options)"
                              >
                                <template #first>
                                  <b-form-select-option
                                    :value="null"
                                    disabled
                                  >
                                    -- select report year here --
                                  </b-form-select-option>
                                </template>
                              </b-form-select>

                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <!-- <b-col
                          cols="12"
                          md="3"
                        >
                          <b-form-group
                            label="Business Tax"
                            label-for="pr_bp_business_tax"
                            label-class="font-weight-bolder"
                          >
                            <b-input
                              id="pr_bp_business_tax"
                              v-model="businessPartner.business_tax"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col> -->

                        <!-- <b-col
                          cols="12"
                          md="3"
                        >
                          <b-form-group
                            label="Tax Status"
                            label-for="pr_bp_tax_status"
                            label-class="font-weight-bolder"
                          >
                            <b-input
                              id="pr_bp_tax_status"
                              v-model="businessPartner.tax_status"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col> -->

                      </b-row>
                    </b-col>

                    <!-- <b-col cols="12">
                      <hr>
                    </b-col> -->

                    <!-- PAYMENTS -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          v-if="String(businessPartner.partner_type) === 'Vendor'"
                          cols="12"
                        >

                          <ValidationProvider
                            #default="{ errors }"
                            vid="is_cash_advance"
                            name="is cash advance"
                          >
                            <b-form-group class="mb-0">
                              <b-form-checkbox
                                id="is_cash_advance"
                                v-model="paymentRequest.is_cash_advance"
                                name="is_cash_advance"
                                unchecked-value="0"
                                value="1"
                                switch
                                :disabled="(fetching.cashAdvanceAccounts || fetching.businessPartners || String(businessPartner.partner_type) !== 'Vendor')"
                              >
                                <strong>Is Cash Advance?</strong>
                              </b-form-checkbox>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider
                            #default="{ errors }"
                            vid="cash_advance"
                            name="cash advance"
                            rules="required_if:is_cash_advance,1"
                          >
                            <b-form-group
                              label="Cash Adv. Account"
                              label-for="pr_cash_advance"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <v-select
                                v-model="selected.cashAdvanceAccount"
                                input-id="pr_cash_advance"
                                type="text"
                                label="partner_name"
                                placeholder="search cash advance account here"
                                :options="lists.cashAdvanceAccounts"
                                :class="(errors.length > 0 ? 'is-invalid' : null)"
                                :disabled="(fetching.cashAdvanceAccounts || fetching.businessPartners || Number(paymentRequest.is_cash_advance) === 0)"
                                :loading="fetching.cashAdvanceAccounts"
                                :filter="fuseBusinessPartnerFilter"
                                @search="getCashAdvanceAccount"
                              >
                                <template #option="{ tin, partner_name }">
                                  <div class="d-flex flex-column">
                                    <span>{{ partner_name }}</span>
                                    <strong>{{ tin }}</strong>
                                  </div>
                                </template>
                                <template #no-options="">
                                  no available cash advance account
                                </template>
                              </v-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <!-- BANK ACCOUNT -->
                        <b-col
                          cols="12"
                        >
                          <div class="d-flex justify-content-between">
                            <div>
                              <ValidationProvider
                                #default="{ errors }"
                                vid="for_deposit"
                                name="for deposit"
                              >
                                <b-form-group class="mb-1">
                                  <b-form-checkbox
                                    id="for_deposit"
                                    v-model="paymentRequest.for_deposit"
                                    switch
                                    value="1"
                                    name="for_deposit"
                                    unchecked-value="0"
                                    :disabled="state.busy"
                                  >
                                    <strong>For Deposit?</strong>
                                  </b-form-checkbox>
                                  <div
                                    v-if="errors.length > 0"
                                    class="invalid-feedback"
                                  >
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </div>
                            <div
                              v-if="Number(paymentRequest.for_deposit) === 1"
                              class="align-self-end align-self-md-start"
                            >
                              <b-button
                                size="sm"
                                variant="link"
                                class="text-success"
                                @click="onShowBankAccounts"
                              >
                                <b-icon icon="search" /> Bank Accounts
                              </b-button>
                            </div>
                          </div>

                          <b-row>

                            <b-col
                              cols="12"
                            >
                              <ValidationProvider
                                #default="{ errors }"
                                vid="deposit_bank"
                                name="deposit bank"
                                rules="required_if:for_deposit,1"
                              >
                                <b-form-group
                                  label="Bank"
                                  label-for="pr_deposit_bank"
                                  label-cols-md="4"
                                  label-class="font-weight-bolder"
                                  class="mb-2"
                                >
                                  <v-select
                                    v-model="selected.bank"
                                    input-id="pr_deposit_bank"
                                    type="text"
                                    label="bank_name"
                                    placeholder="search bank here"
                                    taggable
                                    :options="lists.banks"
                                    :class="(errors.length > 0 ? 'is-invalid' : null)"
                                    :disabled="(fetching.options || Number(paymentRequest.for_deposit) === 0)"
                                    :loading="fetching.options"
                                  >
                                    <template #no-options="">
                                      no available bank
                                    </template>
                                  </v-select>
                                  <div
                                    v-if="errors.length > 0"
                                    class="invalid-feedback"
                                  >
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col
                              cols="12"
                            >
                              <ValidationProvider
                                #default="{ errors }"
                                vid="deposit_account_number"
                                name="account number"
                                rules="required_if:for_deposit,1|max:255"
                              >
                                <b-form-group
                                  label="Account Number"
                                  label-for="pr_deposit_account_number"
                                  label-cols-md="4"
                                  label-class="font-weight-bolder"
                                  class="mb-2"
                                >
                                  <b-input
                                    id="pr_deposit_account_number"
                                    v-model="paymentRequest.deposit_account_number"
                                    type="text"
                                    maxlength="255"
                                    placeholder="enter account number here"
                                    :state="(errors.length > 0 ? false : null)"
                                    :disabled="(fetching.options || Number(paymentRequest.for_deposit) === 0)"
                                  />
                                  <div
                                    v-if="errors.length > 0"
                                    class="invalid-feedback"
                                  >
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col
                              cols="12"
                            >
                              <ValidationProvider
                                #default="{ errors }"
                                vid="deposit_account_name"
                                name="account name"
                                rules="required_if:for_deposit,1|max:255"
                              >
                                <b-form-group
                                  label="Account Name"
                                  label-for="pr_deposit_account_name"
                                  label-cols-md="4"
                                  label-class="font-weight-bolder"
                                  class="mb-2"
                                >
                                  <b-input
                                    id="pr_deposit_account_name"
                                    v-model="paymentRequest.deposit_account_name"
                                    type="text"
                                    maxlength="255"
                                    placeholder="enter account name here"
                                    :state="(errors.length > 0 ? false : null)"
                                    :disabled="(fetching.options || Number(paymentRequest.for_deposit) === 0)"
                                  />
                                  <div
                                    v-if="errors.length > 0"
                                    class="invalid-feedback"
                                  >
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                          </b-row>
                        </b-col>

                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="mop"
                            name="mode of payment"
                            rules="required"
                          >
                            <b-form-group
                              label="Mode Of Payment"
                              label-for="pr_mode_of_payment"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-form-select
                                id="pr_mode_of_payment"
                                v-model="paymentRequest.mop"
                                :options="lists.modeOfPayments"
                                :state="(errors.length > 0 ? false : null)"
                                :disabled="fetching.options"
                              >
                                <template #first>
                                  <b-form-select-option
                                    :value="null"
                                    disabled
                                  >
                                    -- select mode of payment here --
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          v-if="paymentRequest.mop === 'Cash'"
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="branch"
                            name="branch"
                            rules="required"
                          >
                            <b-form-group
                              label="Branch"
                              label-for="pr_branch"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-form-select
                                id="pr_branch"
                                v-model="paymentRequest.branch"
                                :options="lists.branches"
                                :state="(errors.length > 0 ? false : null)"
                                :disabled="state.busy || fetching.options"
                              >
                                <template #first>
                                  <b-form-select-option
                                    :value="null"
                                    disabled
                                  >
                                    -- select branch here --
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="amount"
                            name="amount"
                            rules="required|currency:1"
                          >
                            <b-form-group
                              label="Amount"
                              label-for="pr_amount"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-input
                                id="pr_amount"
                                v-model="maskedPaymentRequest.amount"
                                v-mask="currencyMask"
                                type="text"
                                placeholder="enter amount here"
                                class="text-right"
                                autocomplete="off"
                                :state="(errors.length > 0 ? false : null)"
                                :disabled="fetching.businessPartners"
                                @blur="$event => calculateVAT($event.target.value)"
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="invoice_amount"
                            name="invoice amount"
                            rules="required|currency:0"
                          >
                            <b-form-group
                              label="Invoice Amount"
                              label-for="pr_invoice_amount"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-input
                                id="pr_invoice_amount"
                                v-model="maskedPaymentRequest.invoice_amount"
                                v-mask="currencyMask"
                                type="text"
                                placeholder="0.00"
                                class="text-right"
                                :state="(errors.length > 0 ? false : null)"
                                readonly
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="taxable_amount"
                            name="taxable amount"
                            rules="required|currency:0"
                          >
                            <b-form-group
                              label="Taxable Amount"
                              label-for="pr_taxable_amount"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-input
                                id="pr_taxable_amount"
                                v-model="maskedPaymentRequest.taxable_amount"
                                v-mask="currencyMask"
                                type="text"
                                placeholder="0.00"
                                class="text-right"
                                :state="(errors.length > 0 ? false : null)"
                                readonly
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="withholding_tax"
                            name="with holding tax"
                            rules="required|currency:0"
                          >
                            <b-form-group
                              label="Withholding Tax"
                              label-for="pr_with_holding_tax"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-input
                                id="pr_with_holding_tax"
                                v-model="maskedPaymentRequest.withholding_tax"
                                v-mask="currencyMask"
                                type="text"
                                placeholder="0.00"
                                class="text-right"
                                :state="(errors.length > 0 ? false : null)"
                                readonly
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="amount_due"
                            name="amount due"
                            rules="required|currency:0"
                          >
                            <b-form-group
                              label="Amount Due"
                              label-for="pr_amount_due"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <b-input
                                id="pr_amount_due"
                                v-model="maskedPaymentRequest.amount_due"
                                v-mask="currencyMask"
                                type="text"
                                placeholder="0.00"
                                class="text-right"
                                :state="(errors.length > 0 ? false : null)"
                                readonly
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- <b-col cols="12">
                      <hr>
                    </b-col> -->

                    <!-- <b-col cols="12">
                      <hr>
                    </b-col> -->

                    <!-- APPROVERS -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="has_noted_by"
                            name="has noted by"
                          >
                            <b-form-group class="mb-1">
                              <b-form-checkbox
                                id="has_noted_by"
                                v-model="paymentRequest.has_noted_by"
                                switch
                                value="1"
                                unchecked-value="0"
                                name="has_noted_by"
                                :disabled="fetching.notedByApprovers"
                              >
                                <strong>Use Noted By?</strong>
                              </b-form-checkbox>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider
                            #default="{ errors }"
                            vid="noted_by"
                            name="noted by"
                            rules="required_if:has_noted_by,1"
                          >
                            <b-form-group
                              label="Noted By"
                              label-for="pr_noted_by"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <v-select
                                v-model="selected.notedBy"
                                input-id="pr_noted_by"
                                type="text"
                                append-to-body
                                label="approver_name"
                                placeholder="search noter here"
                                :calculate-position="calculateDropPosition"
                                :options="filteredNotedByLists"
                                :class="(errors.length > 0 ? 'is-invalid' : null)"
                                :disabled="(fetching.notedByApprovers || Number(paymentRequest.has_noted_by) === 0)"
                                :loading="fetching.notedByApprovers"
                              >
                                <template #option="{ approver_name }">
                                  <div class="d-flex flex-column">
                                    <strong>{{ approver_name }}</strong>
                                  </div>
                                </template>
                                <template #no-options="">
                                  no available noter
                                </template>
                              </v-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            vid="approved_by"
                            name="approved by"
                            rules="required"
                          >
                            <b-form-group
                              label="Approved By"
                              label-for="pr_approved_by"
                              label-cols-md="4"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            >
                              <v-select
                                v-model="selected.approvedBy"
                                input-id="pr_approved_by"
                                type="text"
                                small
                                append-to-body
                                label="approver_name"
                                placeholder="search approver here"
                                :calculate-position="calculateDropPosition"
                                :options="filteredApprovedByLists"
                                :class="(errors.length > 0 ? 'is-invalid' : null)"
                                :disabled="(fetching.approvedByApprovers || fetching.budgetCenters)"
                                :loading="fetching.approvedByApprovers"
                              >
                                <template #option="{ approver_name }">
                                  <div class="d-flex flex-column">
                                    <strong>{{ approver_name }}</strong>
                                  </div>
                                </template>
                                <template #no-options="">
                                  no available approver
                                </template>
                              </v-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

              </b-row>
            </b-form>
          </ValidationObserver>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-attachment-line h4" />
              <b>Attachments</b>
            </div>
          </template>
          <Attachment
            :file-path="paymentRequest.attachment_path"
            :file-list="paymentRequest.attachments"
            @onUploaded="onUploadedAttachments"
          />
        </b-tab>

      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between mb-0 px-0 px-md-2">
          <div>
            <b-button
              variant="warning"
              :disabled="state.busy"
              @click="onSubmitPaymentAsDraft"
            >
              {{ SubmitPaymentDraftButton }}
            </b-button>
          </div>
          <div>
            <b-button
              class="mr-1"
              variant="success"
              :disabled="state.busy"
              @click="onSubmitPaymentRequest"
            >
              {{ SubmitPaymentRequestButton }}
            </b-button>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-payment-request"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      aria-hidden="false"
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-file-text-line h4" />
              <b class="is-tab-active">Details</b>
            </div>
          </template>

          <b-row>
            <!-- CENTERS -->
            <b-col
              cols="12"
              md="4"
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Transaction Type"
                        label-for="pr_transaction_type"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_transaction_type"
                          v-model="viewPaymentRequest.bp_transaction_type"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      v-if="viewPaymentRequest.bp_transaction_type === 'With-2307'"
                      cols="12"
                    >
                      <b-form-group
                        label="2307 Period"
                        label-for="pr_report_period"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_report_period"
                          v-model="viewPaymentRequest.report_details"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>

                </b-col>

                <b-col
                  cols="12"
                >

                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Budget Center"
                        label-for="pr_budget_center"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_budget_center"
                          v-model="viewPaymentRequest.budget_name"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Item Description"
                        label-for="pr_item_description"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-textarea
                          id="pr_item_description"
                          v-model="viewPaymentRequest.item_name"
                          rows="2"
                          max-rows="4"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Particulars"
                    label-for="pr_particulars"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-textarea
                      id="pr_particulars"
                      v-model="viewPaymentRequest.particulars"
                      rows="7"
                      max-rows="12"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <!-- BUSINESS PARTNER -->
                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Business Partner"
                        label-for="pr_business_partner"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-textarea
                          id="pr_business_partner"
                          v-model="viewPaymentRequest.bp_name"
                          rows="2"
                          max-rows="4"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="TIN"
                        label-for="pr_bp_tin"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_bp_tin"
                          v-model="viewPaymentRequest.bp_tin"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="ATC"
                        label-for="pr_bp_tax_code"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_bp_tax_code"
                          v-model="viewPaymentRequest.bp_tax_description"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Type"
                        label-for="pr_bp_partner_type"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_bp_partner_type"
                          v-model="viewPaymentRequest.bp_partner_type"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Business Tax"
                        label-for="pr_bp_business_tax"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_bp_business_tax"
                          v-model="viewPaymentRequest.bp_business_tax"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Tax Status"
                        label-for="pr_bp_tax_status"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_bp_tax_status"
                          v-model="viewPaymentRequest.bp_tax_status"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-form-group class="mb-1">
                        <b-form-checkbox
                          id="is_cash_advance"
                          v-model="viewPaymentRequest.is_cash_advance"
                          name="is_cash_advance"
                          unchecked-value="0"
                          value="1"
                          switch
                          disabled
                        >
                          <strong>Is Cash Advance?</strong>
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group
                        label="Cash Adv. Account"
                        label-for="pr_cash_advance"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_amount"
                          v-model="viewPaymentRequest.customer_name"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <!-- PAYMENTS -->
                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Mode Of Payment"
                        label-for="pr_mode_of_payment"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_mode_of_payment"
                          v-model="viewPaymentRequest.mop"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Branch"
                        label-for="pr_branch"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_branch"
                          v-model="viewPaymentRequest.branch"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Amount"
                        label-for="pr_amount"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_amount"
                          v-model="viewPaymentRequest.amount"
                          type="text"
                          class="text-right"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Invoice Amount"
                        label-for="pr_invoice_amount"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_invoice_amount"
                          v-model="viewPaymentRequest.invoice_amount"
                          type="text"
                          class="text-right"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Taxable Amount"
                        label-for="pr_taxable_amount"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_taxable_amount"
                          v-model="viewPaymentRequest.taxable_amount"
                          type="text"
                          class="text-right"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Withholding Tax"
                        label-for="pr_with_holding_tax"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_with_holding_tax"
                          v-model="viewPaymentRequest.withholding_tax"
                          type="text"
                          class="text-right"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Amount Due"
                        label-for="pr_amount_due"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_amount_due"
                          v-model="viewPaymentRequest.amount_due"
                          type="text"
                          class="text-right"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- BANK ACCOUNT -->
                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-form-group class="mb-1">
                        <b-form-checkbox
                          id="for_deposit"
                          v-model="viewPaymentRequest.for_deposit"
                          switch
                          value="1"
                          name="for_deposit"
                          unchecked-value="0"
                          disabled
                        >
                          <strong>For Deposit?</strong>
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group
                        label="Bank"
                        label-for="pr_deposit_bank"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_deposit_bank"
                          v-model="viewPaymentRequest.deposit_bank"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Account Number"
                        label-for="pr_deposit_account_number"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_deposit_account_number"
                          v-model="viewPaymentRequest.deposit_account_number"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Account Name"
                        label-for="pr_deposit_account_name"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="pr_deposit_account_name"
                          v-model="viewPaymentRequest.deposit_account_name"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>

                </b-col>

              </b-row>
            </b-col>

          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-attachment-line h4" />
              <b class="is-tab-active">Attachments</b>
            </div>
          </template>
          <Attachment
            :file-path="viewPaymentRequest.attachment_path"
            :file-list="viewPaymentRequest.attachments"
            :show-uploader="false"
          />
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-file-edit-line h4" />
              <b class="is-tab-active">Approvals</b>
            </div>
          </template>
          <b-row>
            <b-col
              class="mb-3 px-2"
              cols="12"
              md="3"
            >
              <Signatory
                :approver="requesterSignatory"
                :payment-request="viewPaymentRequest"
              />
            </b-col>
            <b-col
              v-for="(approver, key) in viewPaymentRequest.approvals"
              :key="key"
              class="mb-3 px-2"
              cols="12"
              md="3"
            >
              <Signatory
                :approver="approver"
                :payment-request="viewPaymentRequest"
              />
            </b-col>
            <b-col
              v-if="viewPaymentRequest.status === 'Canceled'"
              class="mb-3 px-2"
              cols="12"
              md="3"
            >
              <Signatory
                :approver="cancellationSignatory"
                :payment-request="viewPaymentRequest"
              />
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="col-12 pb-0 d-flex justify-content-end mb-0 px-0">
          <div>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-bank-accounts"
      size="lg"
      title="Bank Accounts"
      scrollable
      centered
      no-close-on-esc
      no-enforce-focus
      hide-header-close
      no-close-on-backdrop
    >
      <div>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Search"
              label-for="search_bank_account"
              label-class="font-weight-bolder"
            >
              <b-input
                id="search_bank_account"
                v-model="tableBankAccounts.filter"
                type="text"
                debounce="1000"
                placeholder="search here"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-table
              small
              hover
              striped
              bordered
              responsive
              show-empty
              :stacked="isMobile()"
              :items="lists.bankAccounts"
              :fields="tableBankAccounts.fields"
              :filter="tableBankAccounts.filter"
              :filter-included-fields="tableBankAccounts.filterOn"
            >
              <template #cell(index)="row">
                {{ (row.index + 1) }}
              </template>

              <template #cell(action)="row">
                <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                  <b-button
                    v-if="String(row.item.id) === String(paymentRequest.bank_account)"
                    size="sm"
                    variant="danger"
                    :disabled="state.busy"
                    @click="onClearBankAccount"
                  >
                    Clear
                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    variant="success"
                    :disabled="state.busy"
                    @click="onSelectBankAccount(row.item)"
                  >
                    Select
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SSharedList } from '@/services'
import PaymentRequestPending from '@/views/user/payments/requests/Pending'
import PaymentRequestDraft from '@/views/user/payments/requests/Drafts'
import PaymentRequestHistories from '@/views/user/payments/requests/Histories'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import Attachment from '@/components/custom/Attachment'
import Signatory from '@/components/custom/Signatory'
import formatter from '@/mixins/formatter'
import formulas from '@/mixins/formulas'
import misc from '@/mixins/misc'
import Fuse from 'fuse.js'
import MOMENT from 'moment'

export default {

  name: 'UserExpensesPaymentRequests',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Payment Request'
  }),

  components: {
    PaymentRequestPending,
    PaymentRequestDraft,
    PaymentRequestHistories,
    Signatory,
    Attachment
  },

  mixins: [formatter, misc, formulas],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        currentTabIndex: this.getCurrentTabIndexByURL()
      },
      validations: {
        amount: 20000,
        minDate: MOMENT().subtract(3, 'y').format('YYYY'),
        maxDate: MOMENT().add(1, 'y').format('YYYY')
      },
      currencyMask: createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        decimalLimit: 2
      }),
      fetching: {
        options: false,
        businessPartners: false,
        cashAdvanceAccounts: false,
        budgetCenters: false,
        itemCenters: false,
        bankAccounts: false,
        notedByApprovers: false,
        approvedByApprovers: false,
        systemOptions: false
      },
      lists: {
        systemOptions: {
          OPTIONS_SELECT_FISCAL_YEAR: 0
        },
        fiscalYears: [],
        businessPartners: [],
        cashAdvanceAccounts: [],
        budgetCenters: [],
        itemCenters: [],
        reportPeriods: [],
        reportYears: [],
        banks: [],
        bankAccounts: [],
        notedByApprovers: [],
        approvedByApprovers: [],
        branches: [
          'Main Office',
          'CDO Office'
        ],
        transactionTypes: [
          { value: 'With-2307', text: 'With 2307' },
          { value: 'Without-2307', text: 'Without 2307' }
        ]
      },
      selected: {
        paymentRequest: null,
        businessPartner: null,
        cashAdvanceAccount: null,
        budgetCenter: null,
        itemCenter: null,
        bank: null,
        notedBy: null,
        approvedBy: null
      },
      businessPartner: {
        id: null,
        partner_code: null,
        partner_name: null,
        partner_type: null,
        custormer_type: null,
        vendor_type: null,
        tin: null,
        tax_status: null,
        transaction_type: null,
        business_tax: null
      },
      cashAdvanceAccount: {
        id: null,
        partner_code: null,
        partner_name: null,
        tin: null
      },
      taxCode: {
        id: null,
        code: null,
        description: null,
        percentage: 0.00,
        atc: null
      },
      maskedPaymentRequest: {
        amount: null,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        amount_due: 0.00
      },
      paymentRequest: {
        id: null,
        fiscal_year: null,
        business_partner: null,
        is_cash_advance: 1,
        customer: null,
        amount: 0.00,
        budget_center: null,
        item_center: null,
        particulars: null,
        transaction_type: null,
        mop: null,
        branch: null,
        report_year: null,
        report_period: null,
        for_deposit: 0,
        deposit_bank: null,
        deposit_account_number: null,
        deposit_account_name: null,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        amount_due: 0.00,
        vat_amount: 0.00,
        has_noted_by: 1,
        noted_by: null,
        approved_by: null,
        approvals: [],
        attachment_path: null,
        attachments: [],
        is_draft: 0,
        is_drafting: false
        // is_non_recurring: true
      },
      viewPaymentRequest: {
        id: null,
        stage: 0,
        bp_code: null,
        bp_name: null,
        bp_tin: null,
        bp_partner_type: null,
        bp_vendor_type: null,
        bp_customer_type: null,
        bp_tax_status: null,
        bp_business_tax: null,
        bp_document_type: null,
        bp_tax_code: null,
        bp_tax_description: null,
        bp_transaction_type: null,
        is_cash_advance: 1,
        customer_code: null,
        customer_name: null,
        customer_tin: null,
        amount: 0.00,
        budget_code: null,
        budget_name: null,
        item_code: null,
        item_name: null,
        budget_amount: null,
        particulars: null,
        mop: null,
        branch: null,
        report_year: null,
        report_period: null,
        report_details: null,
        for_deposit: 1,
        deposit_bank: null,
        deposit_account_number: null,
        deposit_account_name: null,
        vat_amount: 0.00,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        amount_due: 0.00,
        approvals: [],
        attachment_path: null,
        attachments: [],
        status: null,
        remarks: null,
        created_at: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      cancellationSignatory: {
        stage: 0,
        approver_label: 'Disbursement Head',
        approver_action: 'Cancellation of Request',
        approver_name: null,
        last_seen: null,
        status: 'Canceled',
        status_at: null,
        remarks: null
      },
      tableBankAccounts: {
        busy: false,
        filter: null,
        filterOn: ['bank_account_number', 'bank_account_name', 'bank.bank_name'],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 4, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'bank.bank_name', label: 'bank' },
          { mobile: 2, key: 'bank_account_number', label: 'Account Number' },
          { mobile: 3, key: 'bank_account_name', label: 'Account Name' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    filteredNotedByLists () {
      return this.lists.notedByApprovers.filter(
        notedBy => String(this.paymentRequest.approved_by) !== String(notedBy.id)
      )
    },

    filteredApprovedByLists () {
      return this.lists.approvedByApprovers.filter(
        approvedBy => String(this.paymentRequest.noted_by) !== String(approvedBy.id)
      )
    },

    SubmitPaymentRequestButton () {
      if (this.state.editing && !this.paymentRequest.is_drafting) {
        return 'Update Request'
      }

      return 'Submit Request'
    },

    SubmitPaymentDraftButton () {
      if (this.state.editing && this.paymentRequest.is_drafting) {
        return 'Update Draft'
      }

      return 'Save Draft'
    },

    vPaymentRequestPending () {
      return Number(this.paymentRequest.stage) === 1 && this.paymentRequest.status === 'Pending'
    }
  },

  watch: {
    // SELECTED
    'selected.businessPartner' (businessPartner) {
      if (businessPartner?.id) {
        if (businessPartner.tax_code?.id) {
          this.taxCode.percentage = parseFloat(businessPartner.tax_code.percentage || 0.00).toFixed(2)
          this.taxCode.code = businessPartner.tax_code.code
          this.taxCode.description = businessPartner.tax_code.description
          this.taxCode.atc = `${businessPartner.tax_code.code} - ${businessPartner.tax_code.description}`
        } else {
          this.taxCode.atc = null
        }
        this.paymentRequest.business_partner = businessPartner.id
        this.businessPartner.id = businessPartner.id
        this.businessPartner.partner_code = businessPartner.partner_code
        this.businessPartner.partner_name = businessPartner.partner_name
        this.businessPartner.partner_type = businessPartner.partner_type
        this.businessPartner.tin = businessPartner.tin
        this.businessPartner.tax_status = businessPartner.tax_status
        this.businessPartner.business_tax = businessPartner.business_tax
        this.businessPartner.transaction_type = businessPartner.transaction_type
      } else {
        this.taxCode.percentage = 0.00
        this.taxCode.code = null
        this.taxCode.description = null
        this.taxCode.atc = null
        this.businessPartner.id = null
        this.businessPartner.partner_code = null
        this.businessPartner.partner_name = null
        this.businessPartner.partner_type = null
        this.businessPartner.tin = null
        this.businessPartner.tax_status = null
        this.businessPartner.business_tax = null
        this.businessPartner.transaction_type = null
        this.lists.bankAccounts = []
      }
      this.selected.bank = null
      this.paymentRequest.deposit_account_number = null
      this.paymentRequest.deposit_account_name = null
      this.calculateVAT(this.paymentRequest.amount)
    },

    'selected.cashAdvanceAccount' (cashAdvanceAccount) {
      if (cashAdvanceAccount?.id) {
        this.paymentRequest.customer = this.cashAdvanceAccount.id = cashAdvanceAccount.id
        this.cashAdvanceAccount.partner_code = cashAdvanceAccount.partner_code
        this.cashAdvanceAccount.partner_name = cashAdvanceAccount.partner_name
        this.cashAdvanceAccount.tin = cashAdvanceAccount.tin
      } else {
        this.paymentRequest.customer = this.cashAdvanceAccount.id = null
        this.cashAdvanceAccount.partner_code = null
        this.cashAdvanceAccount.partner_name = null
        this.cashAdvanceAccount.tin = null
      }
    },

    'selected.budgetCenter' (budgetCenter) {
      this.paymentRequest.budget_center = budgetCenter?.id || null
    },

    'selected.itemCenter' (itemCenter) {
      this.paymentRequest.item_center = itemCenter?.id || null
    },

    'selected.bank' (bank) {
      this.paymentRequest.deposit_bank = bank?.bank_name || null
    },

    'selected.notedBy' (notedBy) {
      if (Number(this.paymentRequest.has_noted_by) === 1 && notedBy?.id) {
        this.paymentRequest.noted_by = notedBy.id
      } else {
        this.selected.notedBy = this.paymentRequest.noted_by = null
      }
    },

    'selected.approvedBy' (approvedBy) {
      this.paymentRequest.approved_by = approvedBy?.id || null
    },

    // BUSINESS PARTNER

    'businessPartner.particulars' (particulars) {
      if (particulars?.search(/\n/g)) {
        this.businessPartner.particulars = particulars.replace(/\n/g, '')
      }
    },

    'businessPartner.partner_type' (partnerType) {
      if (String(partnerType) === 'Vendor') {
        this.paymentRequest.is_cash_advance = 1
        this.paymentRequest.report_period = this.selected.paymentRequest?.report_period || null
        this.paymentRequest.report_year = this.selected.paymentRequest?.report_year || null
      } else {
        this.paymentRequest.customer = this.cashAdvanceAccount.id = null
        this.cashAdvanceAccount.partner_code = null
        this.cashAdvanceAccount.partner_name = null
        this.cashAdvanceAccount.tin = null
        this.paymentRequest.is_cash_advance = 0
        this.paymentRequest.report_period = null
        this.paymentRequest.report_year = null
      }
    },

    'businessPartner.transaction_type' (transactionType) {
      this.paymentRequest.transaction_type = transactionType || null
    },

    // PAYMENT REQUEST
    'maskedPaymentRequest.amount' (amount) {
      this.calculateVAT(amount, false)
    },

    'paymentRequest.fiscal_year' (fiscalYear) {
      if (this.paymentRequest.budget_center) {
        this.getItemCenters(this.paymentRequest.budget_center, fiscalYear)
      }
    },

    'paymentRequest.transaction_type' () {
      this.calculateVAT(this.paymentRequest.amount)
    },

    'paymentRequest.budget_center' (budgetCenter) {
      if (budgetCenter) {
        this.getItemCenters(budgetCenter, this.paymentRequest.fiscal_year).finally(() => {
          if (this.selected.paymentRequest) {
            this.selected.itemCenter = QUERY.find(
              this.lists.itemCenters, {
                id: this.selected.paymentRequest.item_center_id
              }
            )
          }
          this.getBudgetApprovers(budgetCenter).finally(() => {
            if (this.selected.paymentRequest) {
              this.setCurrentApproveBy(this.selected.paymentRequest.approvals).finally(() => {
                this.selected.paymentRequest = null
              })
            }
          })
        })
      } else {
        this.selected.itemCenter = null
        this.lists.itemCenters = []
        this.selected.approvedBy = null
        this.lists.approvedByApprovers = []
      }
    },

    'paymentRequest.for_deposit' (forDeposit) {
      if (Number(forDeposit) === 0) {
        this.selected.bank = null
        this.paymentRequest.deposit_account_number = null
        this.paymentRequest.deposit_account_name = null
      }
    },

    'paymentRequest.mop' (mop) {
      if (String(mop).toLowerCase() === 'cash') {
        this.paymentRequest.branch = null
      } else {
        this.paymentRequest.branch = 'Main Office'
      }
    },

    'paymentRequest.has_noted_by' (hasNotedBy) {
      if (Number(hasNotedBy) === 0) {
        this.selected.notedBy = null
      }
    },

    'paymentRequest.is_cash_advance' (isCashAdvance) {
      if (Number(isCashAdvance) === 1) {
        this.getCashAdvanceAccounts(this.selected.paymentRequest?.customer_code || '').finally(() => {
          this.selected.cashAdvanceAccount = QUERY.find(
            this.lists.cashAdvanceAccounts, {
              id: this.paymentRequest.customer
            }
          )
        })
      } else {
        this.selected.cashAdvanceAccount = null
        this.lists.cashAdvanceAccounts = []
      }
    }

    // 'paymentRequest.is_non_recurring' (isNonRecurring) {
    //   this.paymentRequest.has_noted_by = Number(isNonRecurring ? 1 : 0)
    // }

  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    // MISCS
    vTabPaymentRequest () {
      return this.$refs.formPaymentRequest && Object.keys(this.$refs.formPaymentRequest.errors).find(
        key => this.$refs.formPaymentRequest.errors[key]?.length > 0
      )
    },

    onKeydownParticulars (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    },

    getCurrentTabIndexByURL () {
      switch (this.$route.params.user) {
        case 'histories' : {
          return 2
        }
        default: {
          return 0
        }
      }
    },

    calculateVAT (amount, changeAmount = true) {
      const transformToNumericValue = amount && amount.length && amount?.search(/[^\d.]/g) ? amount.replace(/[^\d.]/g, '') : amount
      return new Promise(resolve => {
        // this.paymentRequest.is_non_recurring = Number(transformToNumericValue) < this.validations.amount
        const { taxAmount, taxableAmount, withHoldingTax, amountDue } = this.vatCalculator(
          transformToNumericValue,
          this.businessPartner.business_tax,
          this.businessPartner.tax_status,
          this.taxCode.percentage,
          this.paymentRequest.transaction_type
        )
        if (changeAmount) {
          this.maskedPaymentRequest.amount = transformToNumericValue ? parseFloat(transformToNumericValue || 0.00).toFixed(2) : null
        }
        this.paymentRequest.amount = transformToNumericValue ? parseFloat(transformToNumericValue || 0.00).toFixed(2) : null
        this.paymentRequest.vat_amount = taxAmount
        this.paymentRequest.invoice_amount = this.maskedPaymentRequest.invoice_amount = parseFloat(transformToNumericValue || 0.00).toFixed(2)
        this.paymentRequest.taxable_amount = this.maskedPaymentRequest.taxable_amount = taxableAmount
        this.paymentRequest.withholding_tax = this.maskedPaymentRequest.withholding_tax = withHoldingTax
        this.paymentRequest.amount_due = this.maskedPaymentRequest.amount_due = amountDue
        resolve({
          amount: amount ? parseFloat(amount || 0.00).toFixed(2) : null,
          taxableAmount: taxableAmount,
          withHoldingTax: withHoldingTax,
          amountDue: amountDue
        })
      })
    },

    setCurrentNotedBy (approvals) {
      return new Promise(resolve => {
        const notedBy = QUERY.find(approvals, { stage: 1 })
        if (notedBy) {
          this.paymentRequest.has_noted_by = 1
          this.selected.notedBy = QUERY.find(
            this.lists.notedByApprovers, {
              id: notedBy.approver_id
            }
          )
        }
        resolve(notedBy)
      })
    },

    setCurrentApproveBy (approvals) {
      return new Promise(resolve => {
        const approvedBy = QUERY.find(approvals, { stage: 2 })
        if (approvedBy) {
          this.selected.approvedBy = QUERY.find(
            this.lists.approvedByApprovers, {
              id: approvedBy.approver_id
            }
          )
        }
        resolve(approvedBy)
      })
    },

    fuseBusinessPartnerFilter (options, search) {
      const fuse = new Fuse(options, {
        keys: ['partner_name', 'tin'],
        shouldSort: true,
        threshold: 0.3
      })

      if (search.length) {
        return fuse.search(search).map(({ item }) => item)
      }

      return fuse.list
    },

    // LISTS
    async getOptions () {
      this.state.busy = this.fetching.options = true
      return await SSharedList.getCustomList(
        this.toArrayListParam([
          'fiscal_years',
          'banks',
          'mode_of_payments',
          'report_periods',
          'report_years'
        ])
      ).then(({ data }) => {
        this.lists.fiscalYears = data.fiscal_years.map(
          fiscalYear => ({ value: fiscalYear.id, text: fiscalYear.fiscal_name })
        )
        this.lists.banks = data.banks
        this.lists.modeOfPayments = data.mode_of_payments
        this.lists.reportPeriods = data.report_periods
        this.lists.reportYears = data.report_years
      }).catch(() => {
        this.lists.fiscalYears = []
        this.lists.banks = []
        this.lists.modeOfPayments = []
        this.lists.reportPeriods = []
        this.lists.reportYears = []
      }).finally(() => {
        this.state.busy = this.fetching.options = false
      })
    },

    async getSystemOptions () {
      this.state.busy = this.fetching.systemOptions = true
      return await SSharedList.getSystemOptions().then(({ data }) => {
        this.lists.systemOptions = {
          OPTIONS_SELECT_FISCAL_YEAR: data.OPTIONS_SELECT_FISCAL_YEAR
        }
      }).finally(() => {
        this.state.busy = this.fetching.systemOptions = false
      })
    },

    getBusinessPartner (search = '', loading) {
      this.businessPartnerProvider(this, search, loading)
    },

    businessPartnerProvider: QUERY.debounce((context, search, loading) => {
      if (search.length > 0) {
        loading(true)
        if (context.fuseBusinessPartnerFilter(context.lists.businessPartners, search).length <= 2) {
          context.selected.businessPartner = null
          SSharedList.getBusinessPartners(`filter_text=${search || ''}`).then(
            ({ data }) => {
              context.lists.businessPartners = data
            }
          ).catch(() => {
            context.lists.businessPartners = []
          }).finally(() => {
            loading(false)
          })
        } else {
          loading(false)
        }
      }
    }, 500),

    async getBusinessPartners (search = '') {
      this.state.busy = this.fetching.businessPartners = true
      return await SSharedList.getBusinessPartners(`filter_text=${search || ''}`).then(
        ({ data }) => {
          this.lists.businessPartners = data
        }
      ).catch(() => {
        this.lists.businessPartners = []
      }).finally(() => {
        this.state.busy = this.fetching.businessPartners = false
      })
    },

    getCashAdvanceAccount (search = '', loading) {
      this.cashAdvanceProvider(this, search, loading)
    },

    cashAdvanceProvider: QUERY.debounce((context, search, loading) => {
      if (search.length > 0) {
        loading(true)
        if (context.fuseBusinessPartnerFilter(context.lists.cashAdvanceAccounts, search).length <= 0) {
          SSharedList.getBusinessPartners(
            context.objectToUrl({
              partner_type: 'Customer',
              customer_type: 'Cash-Advance',
              filter_text: search
            })
          ).then(
            ({ data }) => {
              context.lists.cashAdvanceAccounts = data
            }
          ).catch(() => {
            context.lists.cashAdvanceAccounts = []
          }).finally(() => {
            loading(false)
          })
        } else {
          loading(false)
        }
      }
    }, 500),

    async getCashAdvanceAccounts (search = '') {
      this.state.busy = this.fetching.cashAdvanceAccounts = true
      return await SSharedList.getBusinessPartners(
        this.objectToUrl({
          partner_type: 'Customer',
          customer_type: 'Cash-Advance',
          filter_text: search
        })
      ).then(({ data }) => {
        this.lists.cashAdvanceAccounts = data
      }).catch(() => {
        this.lists.cashAdvanceAccounts = []
      }).finally(() => {
        this.state.busy = this.fetching.cashAdvanceAccounts = false
      })
    },

    async getBudgetCenters () {
      this.state.busy = this.fetching.budgetCenters = true
      return await SSharedList.getBudgetCenters().then(
        ({ data }) => {
          this.lists.budgetCenters = data
        }
      ).catch(() => {
        this.lists.budgetCenters = []
      }).finally(() => {
        this.state.busy = this.fetching.budgetCenters = false
      })
    },

    async getItemCenters (budgetCenter, fiscalYear = null) {
      this.state.busy = this.fetching.itemCenters = true
      this.selected.itemCenter = null
      return await SSharedList.getItemCenters(
        this.objectToUrl({
          budget_center: budgetCenter,
          fiscal_year: fiscalYear
        })
      ).then(
        ({ data }) => {
          this.lists.itemCenters = data.map(
            ({ id, item }) => ({ id: id, item_name: item.item_name })
          )
        }
      ).catch(() => {
        this.lists.itemCenters = []
      }).finally(() => {
        this.state.busy = this.fetching.itemCenters = false
      })
    },

    async getBankAccounts (businessPartner) {
      this.state.busy = this.fetching.bankAccounts = true
      return await SSharedList.getBankAccounts(
        this.objectToUrl({
          bank_accountable: businessPartner
        })
      ).then(
        ({ data }) => {
          this.lists.bankAccounts = data
        }
      ).catch(() => {
        this.lists.bankAccounts = []
      }).finally(() => {
        this.state.busy = this.fetching.bankAccounts = false
      })
    },

    async getApprovers () {
      this.state.busy = this.fetching.notedByApprovers = true
      this.selected.notedBy = null
      this.lists.notedByApprovers = []
      return await SSharedList.getApprovers().then(({ data }) => {
        this.lists.notedByApprovers = data.map(
          approver => ({ id: approver.id, approver_name: `${approver.first_name} ${approver.last_name}` })
        )
      }).catch(() => {
        this.lists.notedByApprovers = []
      }).finally(() => {
        this.state.busy = this.fetching.notedByApprovers = false
      })
    },

    async getBudgetApprovers (budgetCenter) {
      this.state.busy = this.fetching.approvedByApprovers = true
      this.selected.approvedBy = null
      this.lists.approvedByApprovers = []
      return await SSharedList.getBudgetApprovers(
        this.objectToUrl({
          budget_center: budgetCenter
        })
      ).then(({ data }) => {
        this.lists.approvedByApprovers = data.map(
          approver => ({ id: approver.id, approver_name: `${approver.first_name} ${approver.last_name}` })
        )
      }).catch(() => {
        this.lists.approvedByApprovers = []
      }).finally(() => {
        this.state.busy = this.fetching.approvedByApprovers = false
      })
    },
    // CREATE REQUESTS
    onCreatePaymentRequest () {
      this.state.editing = false

      this.selected.paymentRequest = null
      this.selected.businessPartner = null
      this.selected.cashAdvanceAccount = null
      this.selected.actualCenter = null
      this.selected.budgetCenter = null
      this.selected.itemCenter = null
      this.selected.bank = null
      this.selected.notedBy = null
      this.selected.approvedBy = null

      this.paymentRequest.id = null
      this.paymentRequest.stage = 0
      this.paymentRequest.fiscal_year = null
      this.paymentRequest.business_partner = null
      this.paymentRequest.customer = null
      this.paymentRequest.amount = null
      this.paymentRequest.particulars = null
      this.paymentRequest.transaction_type = null
      this.paymentRequest.mop = null
      this.paymentRequest.branch = 'Main Office'
      this.paymentRequest.report_year = null
      this.paymentRequest.report_period = null
      this.paymentRequest.for_deposit = 0
      this.paymentRequest.deposit_bank = null
      this.paymentRequest.deposit_account_number = null
      this.paymentRequest.deposit_account_name = null
      this.paymentRequest.invoice_amount = 0.00
      this.paymentRequest.taxable_amount = 0.00
      this.paymentRequest.withholding_tax = 0.00
      this.paymentRequest.amount_due = 0.00
      this.paymentRequest.has_noted_by = 1
      this.paymentRequest.approvals = []
      this.paymentRequest.attachment_path = null
      this.paymentRequest.attachments = []
      this.paymentRequest.is_cash_advance = 0
      this.paymentRequest.is_drafting = false
      this.paymentRequest.is_draft = 0

      this.$bvModal.show('modal-payment-request')

      this.getSystemOptions().finally(() => {
        this.getBusinessPartners()
        this.getBudgetCenters().finally(() => {
          this.getApprovers()
        })
      })
    },

    onEditPaymentRequest (paymentRequest) {
      this.state.editing = !!paymentRequest.id

      this.selected.paymentRequest = paymentRequest
      this.selected.businessPartner = null
      this.selected.cashAdvanceAccount = null
      this.selected.actualCenter = null
      this.selected.budgetCenter = null
      this.selected.itemCenter = null
      this.selected.bank = null
      this.selected.notedBy = null
      this.selected.approvedBy = null

      this.$bvModal.show('modal-payment-request')

      this.paymentRequest.id = paymentRequest.id
      this.paymentRequest.fiscal_year = paymentRequest.fiscal_year_id
      this.paymentRequest.stage = paymentRequest.stage
      this.paymentRequest.particulars = paymentRequest.particulars
      this.paymentRequest.transaction_type = paymentRequest.bp_transaction_type
      this.paymentRequest.mop = paymentRequest.mop
      this.paymentRequest.for_deposit = Number(paymentRequest.for_deposit)
      this.paymentRequest.has_noted_by = 0
      this.paymentRequest.approvals = paymentRequest.approvals

      this.paymentRequest.attachment_path = paymentRequest.attachment_path
      this.paymentRequest.attachments = paymentRequest?.attachments || []

      this.paymentRequest.is_drafting = paymentRequest.is_drafting
      this.paymentRequest.is_draft = 0

      this.getSystemOptions().finally(() => {
        this.getBusinessPartners(paymentRequest.bp_tin).finally(() => {
          this.paymentRequest.branch = paymentRequest.branch
          this.paymentRequest.customer = paymentRequest.customer_id
          this.paymentRequest.is_cash_advance = paymentRequest.is_cash_advance
          this.selected.businessPartner = QUERY.find(
            this.lists.businessPartners, {
              id: paymentRequest.business_partner_id
            }
          )
          this.paymentRequest.report_year = paymentRequest?.report_year || null
          this.paymentRequest.report_period = paymentRequest.report_period
        })

        this.getBudgetCenters().finally(() => {
          this.selected.budgetCenter = QUERY.find(
            this.lists.budgetCenters, {
              id: paymentRequest.budget_center_id
            }
          )
          this.selected.bank = QUERY.find(
            this.lists.banks, {
              bank_name: paymentRequest.deposit_bank
            }
          )
          this.maskedPaymentRequest.amount = paymentRequest.amount
          this.paymentRequest.deposit_account_number = paymentRequest.deposit_account_number
          this.paymentRequest.deposit_account_name = paymentRequest.deposit_account_name
        })

        this.getApprovers().finally(() => {
          this.setCurrentNotedBy(paymentRequest.approvals)

          if (this.$refs.formPaymentRequest) {
            this.$refs.formPaymentRequest.reset()
          }
        })
      })
    },

    onViewPaymentRequest (paymentRequest) {
      this.viewPaymentRequest.id = paymentRequest.id
      this.viewPaymentRequest.stage = paymentRequest.stage
      // BUSINESS PARTNER
      this.viewPaymentRequest.bp_code = paymentRequest.bp_code
      this.viewPaymentRequest.bp_name = paymentRequest.bp_name
      this.viewPaymentRequest.bp_tin = paymentRequest.bp_tin
      this.viewPaymentRequest.bp_partner_type = paymentRequest.bp_partner_type
      this.viewPaymentRequest.bp_vendor_type = paymentRequest.bp_vendor_type
      this.viewPaymentRequest.bp_customer_type = paymentRequest.bp_customer_type
      this.viewPaymentRequest.bp_tax_status = paymentRequest.bp_tax_status
      this.viewPaymentRequest.bp_business_tax = paymentRequest.bp_business_tax
      this.viewPaymentRequest.bp_tax_code = paymentRequest.bp_tax_code
      if (paymentRequest.tax_code) {
        this.paymentRequest.bp_tax_description = `${paymentRequest.bp_tax_code} - ${paymentRequest.tax_code.description}`
      }
      this.viewPaymentRequest.bp_document_type = paymentRequest.bp_document_type
      // CASH ADVANCE ACCOUNT
      this.viewPaymentRequest.is_cash_advance = paymentRequest.is_cash_advance
      this.viewPaymentRequest.customer_code = paymentRequest.customer_code
      this.viewPaymentRequest.customer_name = paymentRequest.customer_name
      this.viewPaymentRequest.customer_tin = paymentRequest.customer_tin
      // BANK DETAILS
      this.viewPaymentRequest.for_deposit = Number(paymentRequest.for_deposit)
      this.viewPaymentRequest.deposit_bank = paymentRequest.deposit_bank
      this.viewPaymentRequest.deposit_account_number = paymentRequest.deposit_account_number
      this.viewPaymentRequest.deposit_account_name = paymentRequest.deposit_account_name
      // REQUEST DETAILS
      this.viewPaymentRequest.amount = this.numberFormatter(paymentRequest.amount)
      this.viewPaymentRequest.particulars = paymentRequest.particulars
      this.viewPaymentRequest.bp_transaction_type = paymentRequest.bp_transaction_type
      this.viewPaymentRequest.mop = paymentRequest.mop
      this.viewPaymentRequest.branch = paymentRequest.branch
      this.viewPaymentRequest.report_year = paymentRequest.report_year
      this.viewPaymentRequest.report_period = paymentRequest.report_period
      this.viewPaymentRequest.report_details = `${paymentRequest.report_period} - ${paymentRequest.report_year}`
      this.viewPaymentRequest.invoice_amount = this.numberFormatter(paymentRequest.invoice_amount)
      this.viewPaymentRequest.taxable_amount = this.numberFormatter(paymentRequest.taxable_amount)
      this.viewPaymentRequest.withholding_tax = this.numberFormatter(paymentRequest.withholding_tax)
      this.viewPaymentRequest.amount_due = this.numberFormatter(paymentRequest.amount_due)
      this.viewPaymentRequest.status = paymentRequest.status
      this.viewPaymentRequest.approvals = paymentRequest.approvals
      this.viewPaymentRequest.attachment_path = paymentRequest.attachment_path
      this.viewPaymentRequest.attachments = paymentRequest.attachments
      this.viewPaymentRequest.created_at = paymentRequest.created_at

      // REQUESTER
      if (paymentRequest.requestor) {
        this.requesterSignatory.approver_name = `${paymentRequest.requestor.first_name} ${paymentRequest.requestor.last_name}`
        this.requesterSignatory.status_at = paymentRequest.created_at
        this.requesterSignatory.status = 'Requested'
        this.requesterSignatory.remarks = null

        if (paymentRequest.status === 'Canceled') {
          if (paymentRequest.canceled_at && paymentRequest.canceled_by) {
            this.cancellationSignatory.approver_name = paymentRequest.canceled_by
            this.cancellationSignatory.approver_label = Number(paymentRequest.stage) >= 4 ? 'Disbursement Head' : 'Requester'
            this.cancellationSignatory.status_at = paymentRequest.canceled_at
            this.cancellationSignatory.status = 'Canceled'
            this.cancellationSignatory.remarks = paymentRequest.canceled_remarks
          }
        }
      }

      if (paymentRequest.budget_center) {
        this.viewPaymentRequest.budget_code = paymentRequest.budget_center.budget_code
        this.viewPaymentRequest.budget_name = paymentRequest.budget_center.budget_name
      }

      if (paymentRequest.item_center) {
        this.viewPaymentRequest.budget_amount = paymentRequest.item_center.budget_amount
        if (paymentRequest.item_center.item) {
          this.viewPaymentRequest.item_code = paymentRequest.item_center.item.item_code
          this.viewPaymentRequest.item_name = paymentRequest.item_center.item.item_name
        }
      }

      this.$bvModal.show('modal-view-payment-request')
    },
    // VALIDATIONS PAYMENT REQUESTS
    onSubmitPaymentRequest (event) {
      event.preventDefault()
      this.$refs.formPaymentRequest.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.paymentRequest.is_draft = 0
            this.state.currentTabIndex = 0 // move to pending tab
            this.swalConfirm({
              confirmButtonText: event.target.innerText,
              html: () => {
                if (this.state.editing) {
                  if (this.paymentRequest.is_drafting) {
                    return '<h6>Submit this Draft as a Request?</h6>'
                  }
                  return '<h6>Save your Changes on this Request?</h6>'
                }
                if (this.paymentRequest.attachments.length <= 0) {
                  return '<h6>Submit this as a Request without Attachments?</h6>'
                }
                return '<h6>Submit this as a Request?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  if (this.paymentRequest.is_drafting) {
                    return this.onPutPaymentDraft()
                  }
                  return this.onPutPaymentRequest()
                }
                return this.onPostPaymentRequest()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostPaymentRequest () {
      return await this.$refs.prPending.onPost(this.paymentRequest).then(
        ({ message }) => {
          this.swalSuccess(message).then(() => {
            this.$bvModal.hide('modal-payment-request')
          })
        }
      ).catch(errors => {
        this.$refs.formPaymentRequest.setErrors(errors)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async onPutPaymentRequest () {
      return await this.$refs.prPending.onPut(this.paymentRequest).then(
        ({ message }) => {
          this.swalSuccess(message).then(() => {
            this.$bvModal.hide('modal-payment-request')
          })
        }
      ).catch(errors => {
        this.$refs.formPaymentRequest.setErrors(errors)
      }).finally(() => {
        this.state.busy = false
      })
    },
    // VALIDATIONS DRAFTS
    onSubmitPaymentAsDraft (event) {
      // no validations for drafts
      event.preventDefault()
      this.paymentRequest.is_draft = 1
      this.state.currentTabIndex = 1 // move to drafts tab
      this.swalConfirm({
        confirmButtonText: event.target.innerText,
        html: () => {
          if (this.state.editing && this.paymentRequest.is_drafting) {
            return '<h6>Save your Changes on this Draft?</h6>'
          }
          return '<h6>Save your Request as Draft?</h6>'
        },
        preConfirm: () => {
          this.state.busy = true
          if (this.state.editing && this.paymentRequest.is_drafting) {
            return this.onPutPaymentDraft()
          }
          return this.onPostPaymentDraft()
        }
      })
    },

    async onPostPaymentDraft () {
      return await this.$refs.prDraft.onPost(this.paymentRequest).then(
        ({ message }) => {
          this.swalSuccess(message).then(() => {
            this.$bvModal.hide('modal-payment-request')
          })
        }
      ).catch(errors => {
        this.$refs.formPaymentRequest.setErrors(errors)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async onPutPaymentDraft () {
      return await this.$refs.prDraft.onPut(this.paymentRequest).then(
        ({ message }) => {
          this.swalSuccess(message).then(() => {
            if (this.state.currentTabIndex === 0 && this.paymentRequest.is_drafting) {
              this.$refs.prPending.tablePendingRefresh()
            }
            this.$bvModal.hide('modal-payment-request')
          })
        }
      ).catch(errors => {
        this.$refs.formPaymentRequest.setErrors(errors)
      }).finally(() => {
        this.state.busy = false
      })
    },

    // UPLOAD UDPATES
    onUploadedAttachments (attachment) {
      this.paymentRequest.attachment_path = attachment.attachment_path
      this.paymentRequest.attachments = attachment.attachments
    },
    // BANK ACCOUNT
    onShowBankAccounts () {
      if (this.paymentRequest.business_partner) {
        this.getBankAccounts(this.paymentRequest.business_partner)
      }
      this.$bvModal.show('modal-bank-accounts')
    },

    onSelectBankAccount (bankAccount) {
      this.selected.bank = bankAccount.bank
      this.paymentRequest.deposit_account_name = bankAccount.bank_account_name
      this.paymentRequest.deposit_account_number = bankAccount.bank_account_number
      this.$bvModal.hide('modal-bank-accounts')
    },

    onClearBankAccount () {
      this.selected.bank = null
      this.paymentRequest.deposit_account_name = null
      this.paymentRequest.deposit_account_number = null
    }
  }

}
</script>
